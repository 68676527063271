<template>
  <div class="my-orders">
    <Header />
    <div class="h-24"></div>
    <!-- <div class="breadcrumbs">
      <div class="container py-4 px-4 flex items-center uppercase is-size-6">
        <span class="flex items-center">
          <router-link :to="{ name: 'Home' }" class="has-text-grey"
            >Home</router-link
          >
          <span class="mx-2">/</span>
          <router-link :to="{ name: 'My Orders' }" class="has-text-grey">{{
            $route.name
          }}</router-link>
        </span>
      </div>
    </div> -->
    <b-taglist class="breadcrumbs container p-4">
      <b-tag size="is-large" rounded type="is-white">
        <router-link :to="{ name: 'Home' }" class="has-text-grey">
          <b-icon icon="home" type="is-grey"></b-icon>
        </router-link>
      </b-tag>
      <b-tag size="is-large" rounded type="is-light">
        <router-link :to="{ name: $route.name }" class="has-text-grey">
          <span>{{ $route.name }}</span>
        </router-link>
      </b-tag>
    </b-taglist>
    <div class="container p-4">
      <div
        class="flex items-center justify-between mb-4"
        v-if="data && data.data.length"
      >
        <!-- <div class="is-size-4 has-text-grey" v-if="data">
          {{ $route.name }}
        </div> -->
        <div @keyup.enter="onSearch" class="w-full flex justify-end">
          <b-input
            v-model="search"
            icon="magnify"
            placeholder="Search"
          ></b-input>
        </div>
      </div>
      <b-table
        v-if="data && data.data.length"
        :data="data.data"
        :loading="loading"
        paginated
        pagination-position="bottom"
        backend-pagination
        :total="data.total"
        :per-page="data.per_page"
        :current-page="data.current_page"
        @page-change="onPageChange"
        backend-sorting
        :default-sort="[$store.state.client.column, $store.state.client.order]"
        @sort="onSort"
        striped
      >
        <!-- columns -->
        <b-table-column field="code" label="ID" v-slot="props" sortable>
          <div>{{ props.row.code }}</div>
          <div>
            <router-link
              :to="{ name: 'My Order', params: { id: props.row.id } }"
              class="is-size-7 is-link px-2 border"
              >Details</router-link
            >
          </div>
        </b-table-column>
        <b-table-column
          field="items_count"
          label="No. of Items"
          v-slot="props"
          sortable
        >
          {{ props.row.items_count }}
        </b-table-column>
        <b-table-column field="completion" label="Completion" v-slot="props">
          {{ props.row.completion.toFixed(2) }}%
        </b-table-column>
        <b-table-column
          field="files_count"
          label="Documents"
          v-slot="props"
          sortable
        >
          {{ props.row.files_count }}
        </b-table-column>
        <b-table-column field="amount" label="Amount" v-slot="props">
          $ {{ props.row.amount.toFixed(2) }}
        </b-table-column>
        <b-table-column
          field="updated_at"
          label="Updated"
          v-slot="props"
          sortable
        >
          {{ props.row.updated_at }}
        </b-table-column>
        <b-table-column
          field="created_at"
          label="Created"
          v-slot="props"
          sortable
        >
          {{ props.row.created_at }}
        </b-table-column>
        <!-- !columns -->
      </b-table>
      <div v-else class="is-empty flex items-center justify-center">
        <div class="has-text-grey">
          You have no orders.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/client/Header";
import store from "@/store";
export default {
  title: () => {
    return `My Orders - ${process.env.VUE_APP_TITLE}`;
  },
  data: () => ({
    data: null,
    loading: false
  }),
  components: {
    Header
  },
  computed: {
    search: {
      get() {
        return this.$store.state.client.search;
      },
      set(newValue) {
        store.commit("setClientOrdersSearch", newValue);
      }
    }
  },
  watch: {
    search: _.debounce(function() {
      if (!this.search) this.onSearch();
    }, store.getters.delay)
  },
  methods: {
    onSearch() {
      store.dispatch(this.$route.meta.dispatch).then(res => {
        this.data = res.data;
      });
    },
    onPageChange(page) {
      store.commit("setClientOrdersPage", page);
      store.dispatch(this.$route.meta.dispatch).then(res => {
        this.data = res.data;
      });
    },
    onSort(column, order) {
      store.commit("setClientOrdersPage", 1);
      store.commit("setClientOrdersColumn", column);
      store.commit("setClientOrdersOrder", order);
      store.dispatch(this.$route.meta.dispatch).then(res => {
        this.data = res.data;
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters.userRole == "super-admin") next({ name: "Home" });
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch).then(res => {
        next(vm => {
          vm.data = res.data;
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
}

.my-orders {
  min-height: 100vh;
  .is-empty {
    min-height: calc(100vh - 12rem);
  }
}
</style>
